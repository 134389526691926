<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("timeTable.title") }} // <span class="group-name">Класс </span> - {{ groupInfo.groupName }}
          </template>
          <template v-slot:body>
            <b-form>
              <v-simple-table fixed-header>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">#</th>
                    <th class="text-left">
                      {{ $t("timeTable.day.monday") }}
                    </th>
                    <th class="text-left">
                      {{ $t("timeTable.day.tuesday") }}
                    </th>
                    <th class="text-left">
                      {{ $t("timeTable.day.wednesday") }}
                    </th>
                    <th class="text-left">
                      {{ $t("timeTable.day.thursday") }}
                    </th>
                    <th class="text-left">
                      {{ $t("timeTable.day.friday") }}
                    </th>
                    <th class="text-left">
                      {{ $t("timeTable.day.saturday") }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="dayItem in dayItems" :key="dayItem">
                    <td>{{ dayItem }}</td>
                    <td v-for="day in days" :key="day">
                      <b-form-group id="input-group-2" label-for="input-2">
                        <b-form-select
                            id="input-2"
                            v-model="form[day][dayItem]"
                            :options="subjects"
                            required
                        ></b-form-select>
                      </b-form-group>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </b-form>
            <div>
              <b-button type="submit" @click="onSubmit" variant="primary" id="submitButton">
                {{ $t("common.save") }}
              </b-button>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<style>

#submitButton{
  margin-top: 10px;
}

/*table, th, td {
  border: 1px solid black;
}*/
</style>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";

export default {
  name: "timeTable",
  components: {
    KTPortlet
  },
  data() {
    return {
      groupId: null,
      subjects: [],
      dayItems: [],
      days: [1, 2, 3, 4, 5, 6],
      form: {
        1: {},
        2: {},
        3: {},
        4: {},
        5: {},
        6: {}
      },
      groupInfo: {}
    };
  },
  mounted() {
    let $this = this;
    ApiService.querySecured("dictionaries/groupSubjectsByGroup", {
      params: {groupId: this.$route.params.id}
    }).then(({data}) => {
      this.subjects = data.dictionary;
      $this.reloadData();
    }).catch(({response}) => {
      console.log(response);
    });

    ApiService.querySecured("studentGroups/find", {
      params: {id: this.$route.params.id}
    }).then(({data}) => {
      this.groupInfo = data;
    }).catch(({response}) => {
      console.log(response);
    });
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let data = {
        GroupId: parseInt(this.$route.params.id),
        Days: []
      };
      let form = this.form;
      for (let day in form) {
        let dayLessons = form[day];
        let dayData = {
          Day: parseInt(day),
          Subjects: []
        };
        for (let lessonIndex in dayLessons) {
          let subjectId = dayLessons[lessonIndex];

          dayData.Subjects.push({
            GroupId: parseInt(this.$route.params.id),
            SubjectId: parseInt(subjectId),
            DayOfWeek: parseInt(day),
            LessonNumber: parseInt(lessonIndex)
          });
        }

        data.Days.push(dayData);
      }

      ApiService.postSecured("timeTable/save", data)
          .then(function () {
            alert("Данные сохранены");
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    reloadData() {
      let $this = this;

      ApiService.querySecured("timeTable/getForGroup", {
        params: {groupId: this.$route.params.id}
      })
          .then(({data}) => {
            let timeTable = data.timeTable;
            for (let dayIndex in timeTable.days) {
              let day = timeTable.days[dayIndex];
              for (let lessonIndex in day.subjects) {
                let lesson = day.subjects[lessonIndex];
                $this.form[day.day][lesson.lessonNumber] = lesson.subjectId;
              }
            }

            $this.dayItems = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12];
            console.log("asas");
          })
          .catch(({response}) => {
            console.log(response);
          });
    }
  }
};
</script>
